const xDaysDate = (xDaysInFuture = 0) => {
    const todayInXDaysBase = new Date();
    todayInXDaysBase.setHours(0,0,0,0)
    const todayInXDaysMax = new Date();
    todayInXDaysMax.setHours(23,59,59,999)
    return [
        new Date(todayInXDaysBase.setDate(todayInXDaysBase.getDate() + xDaysInFuture)),
        new Date(todayInXDaysMax.setDate(todayInXDaysMax.getDate() + xDaysInFuture))]
}

function isWithinDay([startTimeToCompare, endTimeToCompare],[withinDateBase, withDateMax]) {
    return startTimeToCompare >= withinDateBase && endTimeToCompare <= withDateMax
}

export function getRecentlyRegisteredTab(schedules = [], recentlyRegistered) {
    for(const scheduleIndex in schedules) {
        const day = schedules[scheduleIndex]
        const scheduledDayStartTime = new Date(day.eventDayDtlStartDttm)
        const scheduledDayEndTime = new Date(day.eventDayDtlEndDttm)

        const [recentlyRegisteredSessionStartTimestamp, recentlyRegisteredSessionEndTimestamp] = [new Date(recentlyRegistered.sessionStartDttm), new Date(recentlyRegistered.sessionEndDttm)]

        if (isWithinDay(
            [recentlyRegisteredSessionStartTimestamp,recentlyRegisteredSessionEndTimestamp],
            [scheduledDayStartTime, scheduledDayEndTime]
        )) {
            return scheduleIndex
        }
    }

    // no match highlighy unlikely select last tab
    return schedules.length - 1
}

export function findUpcomingDayIndex(schedules = []) {
    let earliestUpcomingSession = new Date();

    // plug in 0 for production, any other number to test
    // use negative numbers to test from days x prior to today
    const dayComparisonRange = xDaysDate(0)
    const [dayComparisonRangeBase] = dayComparisonRange

    for(const scheduleIndex in schedules) {
        const day = schedules[scheduleIndex]

        const scheduledDayStartTime = new Date(day.eventDayDtlStartDttm)
        const scheduledDayEndTime = new Date(day.eventDayDtlEndDttm)
        
        
        if (isWithinDay([scheduledDayStartTime, scheduledDayEndTime], dayComparisonRange)) {
            return scheduleIndex
        } else {
            // not within range, check if the scheduled day is greater than the previous lastScheduledSession 
            if(scheduledDayStartTime < earliestUpcomingSession) {
                earliestUpcomingSession = scheduledDayStartTime
            } 
        }
    }
    // return first item when the earliest upcoming sesion is prior to today or the set base date to compare against
    if(earliestUpcomingSession > dayComparisonRangeBase) {
        return 0
    }
    // no matches return last index
    return schedules.length - 1;
}